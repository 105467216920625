
import { RISQUES } from "./DATA/TAUX_COTISATION";
import { Classe1Data } from "./DATA/CLASSE1";
import { classe, Classe2Data } from "./DATA/CLASSE2";
import { Classe1AData } from "./DATA/CLASSE1A";
import { VARS } from "./DATA/VARIABLES";
import { Bareme } from "./DATA/BAREME";
import { SSM } from "./DATA/SSM";


export function Calcul(salaireBrute:number,montantModeration:number,situation:Bareme) {
    let salaireSSM:number=5*SSM
    if(salaireBrute<salaireSSM){
        salaireSSM=salaireBrute;
    }
    var montantAssuranceMaladie:number=salaireSSM*RISQUES.ASSURE.Assurance_maladie_maternité;
    var montantMajoration:number=salaireSSM*RISQUES.ASSURE.Majoration_prestations;
    var montantAssurancePension:number=salaireSSM*RISQUES.ASSURE.Assurance_pension;
    var montantEmployeur=salaireBrute+salaireBrute*(RISQUES.EMPLOYEUR.Assurance_accident+RISQUES.EMPLOYEUR.Assurance_maladie_maternité+RISQUES.EMPLOYEUR.Assurance_pension+RISQUES.EMPLOYEUR.Majoration_prestations+RISQUES.EMPLOYEUR.Mutualité+RISQUES.EMPLOYEUR.Prestations_familiales+RISQUES.EMPLOYEUR.Sante_travail);
    var cotisations:number=montantAssuranceMaladie+montantAssurancePension+montantMajoration;
    var salaireImposable=salaireBrute-cotisations;
    var montantAssuranceDependance:number=(salaireBrute-(SSM*(1/4)))*RISQUES.ASSURE.Assurance_dependance;
    let data:Array<classe>=[];
    let classe_impots:number=0;
    if (situation==0) {
        Object.assign(data, Classe1Data);
        classe_impots=VARS.Classe1_Impots;
    }
        
    else if (situation==1) {Object.assign(data, Classe1AData);classe_impots=VARS.Classe1A_Impots;}
    else if (situation==2) {Object.assign(data, Classe2Data);classe_impots=VARS.Classe2_Impots;}
    
    var montantImpots:number=0;
    for (let value of data) {
        montantImpots++;
        if ((salaireImposable>=value.min) && (salaireImposable<=value.max)){
            montantImpots=salaireImposable*value.R-value.I;
            if(salaireImposable<VARS.RevenuInferieur){
                montantImpots*=1.07;
            }else{
                montantImpots+=(montantImpots*0.09-classe_impots);
            }
            break;
        }
      }
      cotisations+=montantAssuranceDependance;
      var salaireNet:number=salaireBrute-cotisations-montantImpots-montantModeration;
    return [salaireBrute,Number(salaireNet.toFixed(2)),Number(cotisations.toFixed(2)),Number(montantImpots.toFixed(2)),Number(montantModeration.toFixed(2)),Number(montantEmployeur.toFixed(2))];
}