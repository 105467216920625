import { Bareme } from "./BAREME";

export class VARS{
    static RevenuInferieur=12585;
    //Impots
    static Classe1_Impots=81.01;
    static Classe1A_Impots=79.832;
    static Classe2_Impots=162.022;
    // classe Célibataire
    static Classe_Celibataire_Sans_Enfant=Bareme.UN;
    static Classe_Celibataire_Avec_Enfant=Bareme.UN_A;
    static Classe_Celibataire_plus_64=Bareme.UN_A;
    // classe Marié
    static Classe_Marie_Sans_Enfant=Bareme.DEUX;
    static Classe_Marie_Avec_Enfant=Bareme.DEUX;
    static Classe_Marie_plus_64=Bareme.DEUX;
    // classe Divorcé < 3 ans
    static Classe_Divorce_Moins_3ans_Sans_Enfant=this.Classe_Marie_Sans_Enfant;
    static Classe_Divorce_Moins_3ans_Avec_Enfant=this.Classe_Marie_Avec_Enfant;
    static Classe_Divorce_Moins_3ans_plus_64=this.Classe_Marie_plus_64;
    // classe Divorcé > 3 ans 
    static Classe_Divorce_Plus_3ans_Sans_Enfant=this.Classe_Celibataire_Sans_Enfant;
    static Classe_Divorce_Plus_3ans_Avec_Enfant=this.Classe_Celibataire_Avec_Enfant;
    static Classe_Divorce_Plus_3ans_plus_64=this.Classe_Celibataire_plus_64;
    // classe Veuf < 3 ans 
    static Classe_Veuf_Moins_3ans_Sans_Enfant=this.Classe_Marie_Sans_Enfant;
    static Classe_Veuf_Moins_3ans_Avec_Enfant=this.Classe_Marie_Avec_Enfant;
    static Classe_Veuf_Moins_3ans_plus_64=this.Classe_Marie_plus_64;
    // classe Veuf > 3 ans 
    static Classe_Veuf_Plus_3ans_Sans_Enfant=Bareme.UN_A;
    static Classe_Veuf_Plus_3ans_Avec_Enfant=Bareme.UN_A;
    static Classe_Veuf_Plus_3ans_plus_64=Bareme.UN_A;
}