import { GridColDef } from "@mui/x-data-grid";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import AdminPageContainer from "../../../components/AdminPanel/AdminPageContainer/AdminPageContainer";
import CrudUsers from "../../../components/AdminPanel/Crud_users/CrudUsers";
import MUIDialog from "../../../components/AdminPanel/Dialog/MUIDialog";
import "./Users.scss";

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "mailUser", headerName: "E-Mail", flex: 4 },
  { field: "firstNameUser", headerName: "Prénom", flex: 2 },
  { field: "lastNameUser", headerName: "Nom", flex: 2 },
  {
    field: "accessAdmin",
    headerName: "Droits",
    flex: 1.5,
    renderCell: (params: any) => {
      const role = params.row.accessAdmin == 1 ? "Admin" : "Utilisateur";
      return <div className={`cellWithStatus ${role}`}>{role}</div>;
    },
  },
];

const Users = () => {
  const [openModify, setOpenModify] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [firstRender, setFirstRender] = useState(false);
  const [chosenModifyUser, setChosenModifyUser] = useState(-1);
  const [chosenDeleteUser, setChosenDeleteUser] = useState(-1);
  const [rows, setRows] = useState([]);
  const refreshData = () => {
    Axios.get("http://localhost:3001/user/list").then((response: any) => {
      setRows(response.data);
    });
  };
  useEffect(() => {
    if (!firstRender) {
      refreshData();
      setFirstRender(true);
    }
  }, [firstRender]);
  const handleOpenModify = (e: any) => {
    setChosenModifyUser(e.target.value);
    setOpenModify(true);
  };

  const handleCloseModify = () => {
    setOpenModify(false);
  };
  const handleOpenDelete = (e: any) => {
    setChosenDeleteUser(e.target.value);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const actionColumn = [
    {
      field: "actions",
      headerName: "Actions",
      flex: 3,
      renderCell: (params: any) => {
        const hide = params.row.accessAdmin == 1 ? "hide" : "";
        const id = params.row.id;
        return (
          <div className="cellAction">
            <button
              value={id}
              className="modifyButton"
              onClick={handleOpenModify}
            >
              Modifier
            </button>
            <div className={`deleteButton ${hide}`}>
              <button value={id} onClick={handleOpenDelete}>
                Supprimer
              </button>
            </div>
          </div>
        );
      },
    },
  ];
  function handleDelete() {
    const data = {
      idUser: chosenDeleteUser,
    };
    Axios.post("http://localhost:3001/user/delete", data).then(
      (response: any) => {
        if (response.data.deleted == false) {
          console.log(response.data.message);
        } else {
          handleCloseDelete();
        }
      }
    );
  }
  return (
    <>
      <AdminPageContainer
        label="utilisateurs"
        rows={rows}
        cols={columns.concat(actionColumn)}
        refreshRows={refreshData}
      >
        <CrudUsers label="Ajouter" />
      </AdminPageContainer>

      {/** Click Modifier */}
      <MUIDialog
        open={openModify}
        handleClose={handleCloseModify}
        buttonContent={"Modifier"}
        titleContent={"Modification"}
      >
        <CrudUsers label="Modifier" value={chosenModifyUser} />
      </MUIDialog>

      {/** Click supprimer */}
      <MUIDialog
        open={openDelete}
        handleClose={handleCloseDelete}
        buttonContent={"Supprimer"}
        titleContent={"Suppression"}
        value={chosenDeleteUser}
        onClick={handleDelete}
        content={"Est-ce que vous êtes sûr de supprmier cet utilisateur?"}
      ></MUIDialog>
    </>
  );
};

export default Users;
