import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { Link } from "react-router-dom";
import vars from "../../styles/variables.module.scss";
import "./Competivite.scss";
import {
  FR_SMIC_rows,
  FR_SMOY_AD_rows,
  FR_SMOY_C_rows,
  GD_SMIC_rows,
  GD_SMOY_rows,
  PIBRows,
} from "./data";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: vars.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Competitivite = () => {
  return (
    <>
      <div className="container container_hero">
        <div>
          <h1>Compétitivité salaiale</h1>
          <p>
            Le Grand-Duché de Luxembourg est statistiquement le pays le plus
            riche du monde.
          </p>
          <p>
            Selon le Fonds Monétaire International (FMI), le Produit Intérieur
            Brut (PIB) en Parité de Pouvoir d'Achat (PPA) par habitant pour 2010
            était de :
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: 400,
                margin: "auto",
              }}
            >
              <Table
                sx={{
                  maxWidth: 400,
                  margin: "auto",
                  backgroudn: "transparent",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Pays</StyledTableCell>
                    <StyledTableCell align="center">USD</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {PIBRows.map((row) => (
                    <StyledTableRow key={row.country}>
                      <StyledTableCell component="th" scope="row">
                        {row.country}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.usd}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <p className="center">
              Le PIB PPA par habitant est un indicateur de richesse et de niveau
              de vie.
            </p>
          </p>
          <div>
            <h5 className="question">
              Ceci implique-t-il que le Grand-Duché de Luxembourg soit
              socialement moins compétitif que ses voisins ?
            </h5>
            <p>
              Comparons au 01/01/2014 deux entreprises, l'une française l'autre
              luxembourgeoise, qui souhaiteraient toutes deux embaucher un
              salarié au minimum légal, puis ces mêmes entreprises qui
              souhaiteraient embaucher un informaticien et un comptable de 5 ans
              d'expérience.
            </p>
            <p>
              Parlons tout d'abord du salaire minimum au Grand-Duché et en
              France, ramené au nombre d'heures travaillées :
            </p>
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: 700,
                margin: "auto",
              }}
            >
              <Table
                sx={{
                  maxWidth: 700,
                  margin: "auto",
                  backgroudn: "transparent",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Grand-Duché de Luxembourg</StyledTableCell>
                    <StyledTableCell align="center">
                      Données brutes
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Durée du travail
                    </StyledTableCell>
                    <StyledTableCell align="center">Base 100</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {GD_SMIC_rows.map((row) => (
                    <StyledTableRow key={row.gd}>
                      <StyledTableCell component="th" scope="row">
                        {row.gd}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.db}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.duree}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.B100}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <p className="center">
              (1) Incluant une cotisation Mutualité classe 1 de 0,47% à la
              charge de l'employeur.
            </p>
            <p className="center">(2) Au 01/01/2014</p>
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: 700,
                margin: "auto",
              }}
            >
              <Table
                sx={{
                  maxWidth: 700,
                  margin: "auto",
                  backgroudn: "transparent",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>France</StyledTableCell>
                    <StyledTableCell align="center">
                      Données brutes
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Durée du travail
                    </StyledTableCell>
                    <StyledTableCell align="center">Base 100</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {FR_SMIC_rows.map((row) => (
                    <StyledTableRow key={row.gd}>
                      <StyledTableCell component="th" scope="row">
                        {row.gd}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.db}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.duree}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.B100}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <p className="center">
              (3) Incluant la réduction Fillon sur les charges patronales
              employeur.
            </p>
            <p>
              Si nous parlons de salaire minimum légal, nous constatons en base
              100 un surcoût pour l'employeur luxembourgeois de 16,26 % et un
              gain pour le salarié de 31,17 %. Le Grand-Duché de Luxembourg est
              effectivement non compétitif sur les bas salaires.
            </p>
            <p>
              Prenons le cas maintenant d'un développeur informatique et d'un
              comptable, disposant tous deux de cinq ans d'expérience :
            </p>
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: 700,
                margin: "auto",
              }}
            >
              <Table
                sx={{
                  maxWidth: 700,
                  margin: "auto",
                  backgroudn: "transparent",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Grand-Duché de Luxembourg</StyledTableCell>
                    <StyledTableCell align="center">
                      Données brutes
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Durée du travail
                    </StyledTableCell>
                    <StyledTableCell align="center">Base 100</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {GD_SMOY_rows.map((row) => (
                    <StyledTableRow key={row.gd}>
                      <StyledTableCell component="th" scope="row">
                        {row.gd}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.db}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.duree}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.B100}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <p></p>
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: 700,
                margin: "auto",
              }}
            >
              <Table
                sx={{
                  maxWidth: 700,
                  margin: "auto",
                  backgroudn: "transparent",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      Analyste développeur en France
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Données brutes
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Durée du travail
                    </StyledTableCell>
                    <StyledTableCell align="center">Base 100</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {FR_SMOY_AD_rows.map((row) => (
                    <StyledTableRow key={row.gd}>
                      <StyledTableCell component="th" scope="row">
                        {row.gd}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.db}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.duree}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.B100}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <p></p>
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: 700,
                margin: "auto",
              }}
            >
              <Table
                sx={{
                  maxWidth: 700,
                  margin: "auto",
                  backgroudn: "transparent",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Comptable en France</StyledTableCell>
                    <StyledTableCell align="center">
                      Données brutes
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Durée du travail
                    </StyledTableCell>
                    <StyledTableCell align="center">Base 100</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {FR_SMOY_C_rows.map((row) => (
                    <StyledTableRow key={row.gd}>
                      <StyledTableCell component="th" scope="row">
                        {row.gd}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.db}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.duree}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.B100}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <p>
              Pour le poste de développeur ('analyste développeur' dans les
              nomenclatures françaises), nous obtenons en base 100 un surcoût
              pour l'employeur français de 22,23 % et un manque à gagner pour le
              salarié français de 16,64 %.
            </p>
            <p>
              Pour le poste de comptable, nous obtenons en base 100 un surcoût
              pour l'employeur français de 38,13 %, mais un gain pour le salarié
              luxembourgeois de 5,55 %.
            </p>
            <p>
              La délocalisation des activités de support (comptabilité,
              informatique) au Grand-Duché de Luxembourg permettra à une
              entreprise française de gagner de 20 à 40% sur sa masse salariale
              tout en faisant gagner à ses salariés de 5 à 20% sur leur net.
            </p>
            <p>
              Faites vos propres simulations en utilisant notre{" "}
              <Link className="a" to="/simulateur">
                simulateur de salaire
              </Link>{" "}
              en ligne.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Competitivite;
