import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

interface Props {
  content?: string;
  children?: any;
  open: boolean;
  titleContent: string;
  buttonContent: string;
  onClick?: () => void;
  handleClose?: () => void;
  value?: any;
}
export default function MUIDialog({
  children,
  content,
  open,
  titleContent,
  onClick,
  handleClose,
  buttonContent,
  value,
}: Props) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{titleContent}</DialogTitle>
      <DialogContent>
        {children}
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttonContent === "Supprimer" ? (
          <Button onClick={onClick}>{buttonContent}</Button>
        ) : (
          ""
        )}
        <Button onClick={handleClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
  );
}
