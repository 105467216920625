import React from "react";
import Chart from "react-apexcharts";
interface Props {
  brut?: number;
  net?: number;
  cotisation?: number;
  impots?: number;
  moderation?: number;
}
const PieChart = ({ brut, net, cotisation, impots, moderation }: Props) => {
  const options = {
    labels: ["Cotisations", "Impôts", "Modération", "Salaire Net"],
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: true,
            total: {
              label: "Salaire Brut",
              value: brut,
              show: true,
              showAlways: true,
            },
          },
        },
      },
    },
  };
  const series = [cotisation, impots, moderation, net];
  return (
    <div className="chart1">
      <h3>Répartition de votre salaire</h3>
      <Chart
        options={options}
        series={series}
        type="donut"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default PieChart;
