// barème 2017
export interface classe{
    min:number;
    max:number;
    R:number;
    I:number;
}
export const Classe2Data:Array<classe>=[
    {min:  0.00 ,max:1960.00 , R: 0.00 , I: 0.00000},
{min: 1965.00,max: 2270.00, R: 0.08 , I: 157.0000},
{min: 2275.00,max: 2585.00, R: 0.09 , I: 179.74500
},{min: 2590.00,max: 2895.00, R: 0.10 , I: 205.61000
},{min: 2900.00,max: 3210.00, R: 0.11 , I: 234.59500
},{min: 3215.00,max: 3520.00 , R:0.12 , I: 266.70000
},{min: 3525,max: 3845, R: 0.14 , I: 337.15000
},{min: 3850.00,max: 4170.00, R: 0.16 , I: 414.08000
},{min: 4175,max: 4490.00, R:0.18 , I: 497.49000
},{min: 4495,max: 4.815, R: 0.20 , I: 587.38000
},{min: 4820.00,max: 5140.00 , R:0.22 , I: 683.75000
},{min: 5145,max: 5465 , R:0.24 , I: 786.60000
},{min: 5470.00,max: 5790.00 , R:0.26 , I: 895.93000
},{min: 5795,max: 6110.00 , R:0.28 , I: 1011.74000
},{min: 6115,max: 6435, R: 0.30 , I: 1134.03000
},{min: 6440.00,max: 6760.00, R: 0.32 , I: 1262.80000
},{min: 6765,max: 7085, R: 0.34 , I: 1398.05000
},{min: 7090.00,max: 7410.00 , R:0.36 , I: 1539.78000
},{min: 7415,max: 7730.00, R: 0.38 , I: 1687.99000
},{min: 7735,max: 16750.00, R: 0.39 , I: 1765.33500
},{min: 16755,max: 25085, R: 0.40 , I: 1932.85500
},{min: 25090.0,max:33415, R: 0.41 , I: 2183.70500},
{min: 33420.0,max:9999999.99 , R:0.42 , I: 2517.89500}]

