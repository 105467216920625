import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { Link } from "react-router-dom";
import pagenotfoundImage from "../../../assets/images/illustrations/ill_error404.jpg";
import "./Page404.scss";
const Page404 = () => {
  return (
    <div className="pageNotFound">
      <h1>Oops..! 404 Page introuvable </h1>
      <p>La page que vous recherchez est introuvable.</p>
      <Link to="/">
        <span className="btnHome">Accueil</span>
      </Link>
      <img
        src={pagenotfoundImage}
        height="500"
        width="500"
        alt="not foun illustration"
      />
    </div>
  );
};

export default Page404;
