export const questions =[
    {
    summary: "Quand est-ce qu'une domiciliation est interdite ?",
    details: "Toute société ayant une activité économique réelle (signature de contrats, émission de factures à des tiers) doit obligatoirement demander une autorisation d'établissement. Toute société disposant d'une autorisation d'établissement a l'obligation de disposer de locaux adaptés à la nature de son activité. Donc domiciliation interdite."
},
{
    summary: "Quels sont les services liés à une domiciliation ?",
    details: "De la définition légale, il faut retenir qu'une domiciliation simple (c'est-à-dire sans services associés) est interdite.Les missions sociales (établissement de fiches de salaires, déclarations sociales, etc.) sont optionnelles.",
},
{
    summary:"Quels coûts pour une domiciliation ?",
    details:"Au 01/01/2017, la domiciliation vous sera facturée 2 570 EUR H.T. par an (+TVA à 7 %), à comparer au coût d'une location simple d'un bureau sur Luxembourg ville (700 à 1 000 EUR par mois + charges locatives).Les prestations comptables et fiscales associées à la domiciliation sont facturées en fonction de l'activité réelle de la société, avec un minimum de 2 870 EUR H.T. par an (société sans activité économique)."
},    
{
    summary:"Quelles sociétés peuvent-être domiciliées chez nous ?",
    details:"Nous domicilions exclusivement des sociétés anonymes (S.A.) et des sociétés à responsabilité limitée (SARL) de droit luxembourgeois, dont l'objet social est légal.Nous portons enfin une attention particulière à la prévention du blanchiment d'argent et du financement du terrorisme, conformément aux normes de notre profession."
}, 
]