// barème 2017
export const Classe1Data=[
    {min: 0,     max:1020, R:0, I:0},
    {min:  1025, max:1175, R:0.08, I:81.9},
    {min:  1180, max:1325, R:0.09, I:93.69750},
    {min:  1340, max:1490, R:0.1, I:107.055},
    {min:  1495, max:1645, R:0.11, I:121.97250},
    {min:  1650, max:1800, R:0.12, I:138.45},
    {min:  1805,max: 1965,R: 0.14, I: 174.52500},
    {min:  1970,max: 2125,R: 0.16 , I: 213.84000},
    {min:  2130,max: 2285,R: 0.18 , I: 256.39500},
    {min:  2290,max: 2450,R: 0.20 , I: 298.19000},
    {min:  2455,max: 2610,R: 0.22 , I: 351.22500},
    {min:  2615,max: 2775,R: 0.24 , I: 403.50000},
    {min:  2780,max: 2935,R: 0.26 , I: 459.01500},
    {min:  2940,max: 3095,R: 0.28 , I: 517.77000},
    {min:  3100,max: 3260,R: 0.30 , I: 579.76500},
    {min:  3265,max: 3420,R: 0.32 , I: 645.00000},
    {min:  3425,max: 3585,R: 0.34 , I: 713.47500},
    {min:  3590,max: 3745,R: 0.36 , I: 785.19000},
    {min:  3750,max: 3905,R: 0.38 , I: 860.14500},
    {min:  3910,max: 8415,R: 0.39 , I: 899.24250},
    {min:  8420,max: 12585,R: 0.40 , I: 983.42750},
    {min: 12590,max: 16750,R: 0.41 , I: 1109.27750},
    {min: 16755,max: 9999999.99,R: 0.42 , I: 1276.79750},
]

