import "bootstrap/dist/css/bootstrap.css";
import React, { useState } from "react";
import ill_simulateur from "../../assets/images/illustrations/ill_simulateur.png";
import PieChart from "../../components/PieChart/PieChart";
import { Bareme, Status } from "./DATA/BAREME";
import { SSM } from "./DATA/SSM";
import { Calcul } from "./ScriptSimulateur";
import "./simulateur.scss";

const Simulateur = () => {
  const [clickedButton, setClickedButton] = useState("");
  const [status, setStatus] = useState();
  const [situation, setSituation] = useState(Bareme.UN);
  const [dataBrut, setDataBrut] = useState("");
  const [dataModeration, setDataModeration] = useState("");
  const [result, setResult] = useState<number[]>([]);

  const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (Number(dataBrut) < Number(dataModeration)) {
      alert("Votre salaire doit être supérieur au taux de modération");
    } else if (SSM > Number(dataBrut)) {
      alert("Votre salaire doit être supérieur ou égâle à " + SSM);
    } else {
      changeResult();
      const button: HTMLButtonElement = event.currentTarget;
      setClickedButton(button.name);
    }
  };
  function changeResult() {
    setResult(Calcul(Number(dataBrut), Number(dataModeration), situation));
  }
  function changeBrut(val: any) {
    let lastChar = val.target.value[val.target.value.length - 1];
    let value = val.target.value;
    if ((!isNaN(lastChar) || value === "") && Number(value) <= 9999999)
      setDataBrut(val.target.value);
  }
  function changeModeration(val: any) {
    let value = val.target.value;
    let lastChar = value[val.target.value.length - 1];
    if ((!isNaN(lastChar) || value === "") && Number(value) <= 9999999)
      setDataModeration(value);
  }
  function changeStatus(val: any) {
    setStatus(val.target.value);
  }
  function changeSituation(val: any) {
    setSituation(val.target.value);
  }
  return (
    <>
      <div className="container container_hero">
        <div className="definition">
          <h4 className="grey">Simulateur de salaire</h4>
          <p>
            Ce simulateur de salaire utilise les paramètres fiscaux et sociaux
            réels au jour où vous réalisez votre simulation. Il a cependant été
            volontairement limité dans ses fonctionnalités :
            <ul className="listSimulateur">
              <li>Le simulateur est basé sur un temps complet (173h/mois)</li>
              <li>Les avantages en nature ne sont pas pris en compte</li>
              <li>Les heures supplémentaires ne sont pas prises en compte</li>
              <li>
                La cotisation Mutualité (à charge de l'employeur) fait référence
                à la classe 1 (absentéisme financier 0,75 %)
              </li>
            </ul>
          </p>
        </div>
        <div className="illus">
          <img className="img-fluid" src={ill_simulateur} alt="illustration" />
        </div>
      </div>
      <div className="container InputSimulateur">
        <div className="SituationFamiliale">
          <h3>Situation Familiale</h3>
          <div className="status">
            <h4>Status</h4>
            <ul>
              <li>
                <input
                  type="radio"
                  value={Status.Celibataire}
                  name="status"
                  defaultChecked
                  onChange={changeStatus}
                />{" "}
                Célibataire
              </li>
              <li>
                <input
                  type="radio"
                  value={Status.Marie}
                  name="status"
                  onChange={changeStatus}
                />{" "}
                Marié
              </li>
              <li>
                <input
                  type="radio"
                  value={Status.Divorce_M3}
                  name="status"
                  onChange={changeStatus}
                />{" "}
                Divorcé depuis moins de 3 ans
              </li>
              <li>
                <input
                  type="radio"
                  value={Status.Divorce_P3}
                  name="status"
                  onChange={changeStatus}
                />{" "}
                Divorcé depuis plus de 3 ans
              </li>
            </ul>
          </div>
          <div className="situation">
            <h4>Situation</h4>
            <ul>
              <li>
                <input
                  type="radio"
                  value={
                    status === Status.Marie || status === Status.Divorce_M3
                      ? Bareme.DEUX
                      : Bareme.UN
                  }
                  name="situation"
                  defaultChecked
                  onChange={changeSituation}
                />{" "}
                Sans enfant
              </li>
              <li>
                <input
                  type="radio"
                  value={
                    status === Status.Celibataire ||
                    status === Status.Divorce_P3
                      ? Bareme.UN_A
                      : Bareme.DEUX
                  }
                  name="situation"
                  onChange={changeSituation}
                />{" "}
                Avec enfant(s)
              </li>
            </ul>
          </div>
          <h3>Données</h3>
          <div className="Donnees">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingSalaire"
                placeholder="0"
                value={dataBrut}
                onChange={changeBrut}
              />
              <label className="floatingSalaire">Salaire mensuel brut</label>
              <div className="invalid-feedback">
                Please provide a valid city.
              </div>
            </div>
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                id="floatingModeration"
                placeholder="0"
                value={dataModeration}
                onChange={changeModeration}
              />
              <label htmlFor="floatingModeration">Modération d'impôts</label>
            </div>
            <button className="calculate" onClick={buttonHandler}>
              Calculer
            </button>
          </div>
        </div>

        <div className="chart">
          <div>
            Salaire net: <span className="text-success">{result[1] | 0} €</span>{" "}
          </div>
          <div>
            Montant employeur:{" "}
            <span className="text-success">{result[5] | 0} €</span>{" "}
          </div>
          <PieChart
            net={result[1] | 0}
            brut={result[0]}
            moderation={result[4] | 0}
            cotisation={result[2] | 0}
            impots={result[3] | 0}
          />
        </div>
      </div>
    </>
  );
};

export default Simulateur;
