export const listHeader=[
    {
        id:0,
        str:"Caractéristiques"
    }
    ,
    {
        id:1,
        str:"Société anonyme" 
    },
    {
        id:2,
        str:"Société à responsabilité limitée"
    },
    
]
export const listCapitalSocial=[
    {   
        id:0,
        caracteristiques: "Capital social minimum",
        SA:"30000 €",
        SARL: "12000 €"
    },
    {
        id:1,
        caracteristiques: "Capital social libéré",
        SA:"Minimum 25 %",
        SARL: "100 %"
    },
    {
        id:2,
        caracteristiques: "Devise du capital",
        SA:"Toutes devises librement convertibles",
        SARL: "Toutes devises librement convertibles"
    },
    {
        id:3,
        caracteristiques: "Nombre d'actionnaires/associés",
        SA:"Minimum 1",
        SARL: "	De 1 à 100"
    },
    {
        id:4,
        caracteristiques: "Statut des actionnaires/associés",
        SA:"Personne(s) morale(s) ou physique(s)",
        SARL: "Personne(s) morale(s) ou physique(s)"
    },
    {
        id:5,
        caracteristiques: "Forme des actions/parts sociales",
        SA:"Nominatives ou dématérialisées",
        SARL: "Nominatives"
    },
    {
        id:6,
        caracteristiques: "Cession des actions/parts sociales",
        SA:"Pas de restrictions légales",
        SARL: "Restrictions légales applicables"
    }
]
export const listAdministration=[
    {
        id:0,
        caracteristiques: "Administration",
        SA:"Conseil d'administration ou Directoire sous le contrôle d'un conseil de surveillance",
        SARL: "Gérant(s)"
    },
    {
        id:1,
        caracteristiques: "Nombre",
        SA:"Minimum 3 (1 pour la S.A. unipersonnelle)",
        SARL: "Gérant(s)"
    },
    {
        id:2,
        caracteristiques: "Obligation de résidence",
        SA:"Le porteur de l'autorisation d'établissement doit dans les faits résider à moins de 150 km du siège social de la société.",
        SARL: "Le porteur de l'autorisation d'établissement doit dans les faits résider à moins de 150 km du siège social de la société."
    }
    ,
    {
        id:3,
        caracteristiques: "Pouvoirs de l'administration",
        SA:"Illimités dans le cadre de l'objet social",
        SARL: "Illimités dans le cadre de l'objet social"
    }
]

export const listComptesAnnuels=[
    {
        id:0,
        caracteristiques: "Comptabilité",
        SA:"Obligatoire",
        SARL: "Obligatoire"
    },
    {
        id:1,
        caracteristiques: "Comptes annuels",
        SA:"Obligatoire",
        SARL: "Obligatoire"
    }
]

export const listApprobationDesComptesAnnuels=[
    {
        id:0,
        caracteristiques: "Responsabilité",
        SA:"Assemblée des actionnaires",
        SARL: "Assemblée des associés pour les sociétés ayant plus de 60 associés (obligation légale) ou associés individuellement (sans tenue obligatoire d'une assemblée, sauf disposition statutaire contraire)"
    },
    {
        id:1,
        caracteristiques: "Délai",
        SA:"Selon les statuts ou au plus tard 6 mois après la clôture",
        SARL: "Selon les statuts ou au plus tard 6 mois après la clôture"
    },
    {
        id:2,
        caracteristiques: "Dépôts et publications",
        SA:"1 mois au plus tard après l'approbation",
        SARL: "1 mois au plus tard après l'approbation"
    },
    {
        id:3,
        caracteristiques: "Distribution de dividendes",
        SA:"Sur la base de comptes annuels approuvés",
        SARL: "Sur la base de comptes annuels approuvés"
    },
    {
        id:4,
        caracteristiques: "Dividende intérimaire",
        SA:"Possible sur la base du respect des conditions légales et du rapport d'un réviseur d'entreprises constatant l'existence de réserves ou d'un résultat distribuables",
        SARL: "Non prévu par la loi : se rencontre dans la pratique"
    }
]