  export const PIBRows = [
    {country: "Luxembourg", usd: "108 831"} ,
    {country: "Belgique", usd: "42 630"} ,
    {country: "Allemagne", usd: "40 631"} ,
    {country: "France", usd: "41 018"} ,
  ];

  export const GD_SMIC_rows=[
      {gd :"Salaire social minimum", db:"(1)  1 921,03 EUR",duree:"173 heures / mois",B100:"1 110,42"},
      {gd :"Coût total employeur", db:"(2)  2 165,57 EUR",duree:"173 heures / mois",B100:"1 251,77"},
      {gd :"Net employé avant impôts", db:"1 687,70 EUR",duree:"173 heures / mois",B100:"9 75,55"},
      {gd :"Ratio coût total / net employé", db:"1,2831",duree:"",B100:""},
    ]
    export const FR_SMIC_rows=[
        {gd :"SMIC", db:"1 445,38 EUR",duree:"151,67 heures / mois",B100:"952,98"},
        {gd :"Coût total employeur", db:"(3)  1 633,00 EUR",duree:"151,67 heures / mois",B100:"1 076,68"},
        {gd :"Net employé avant impôts", db:"1 128,00 EUR",duree:"151,67 heures / mois",B100:"743,72"},
        {gd :"Ratio coût total / net employé", db:"1,4477",duree:"",B100:""},
      ]
      export const GD_SMOY_rows=[
        {gd :"Salaire moyen", db:"2 800,00 EUR",duree:"	173 heures / mois",B100:"1 618,50"},
        {gd :"Coût total employeur", db:"3 156,44 EUR",duree:"173 heures / mois",B100:"1 824,53"},
        {gd :"Net employé avant impôts", db:"2 457,23 EUR",duree:"173 heures / mois",B100:"1 420,36"},
        {gd :"Ratio coût total / net employé", db:"1,2846",duree:"",B100:""},
      ]     
      export const FR_SMOY_AD_rows=[
        {gd :"Salaire moyen", db:"2 400,00 EUR",duree:"	151,67 heures / mois",B100:"1 582,38"},
        {gd :"Coût total employeur", db:"3 382,32 EUR",duree:"151,67 heures / mois",B100:"2 230,05"},
        {gd :"Net employé avant impôts", db:"1 847,00 EUR",duree:"151,67 heures / mois",B100:"1 217,78"},
        {gd :"Ratio coût total / net employé", db:"1,8313",duree:"",B100:""},
      ]    
      export const FR_SMOY_C_rows=[
        {gd :"Salaire moyen", db:"2 650,00 EUR",duree:"151,67 heures / mois",B100:"1 747,21"},
        {gd :"Coût total employeur", db:"3 822,31 EUR",duree:"151,67 heures / mois",B100:"2 520,15"},
        {gd :"Net employé avant impôts", db:"2 041,00 EUR",duree:"151,67 heures / mois",B100:"1 345,68"},
        {gd :"Ratio coût total / net employé", db:"1,8728",duree:"",B100:""},
      ]     