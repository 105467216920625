import React, { useState } from "react";
import {
  listAdministration,
  listApprobationDesComptesAnnuels,
  listCapitalSocial,
  listComptesAnnuels,
  listHeader,
} from "./data";
import "./Tabs.scss";

function Tabs() {
  const [index, setIndex] = useState(0);
  return (
    <div className="Tabs">
      <div className="tabsList">
        <div
          className={`tabHead ${index === 0 ? `active` : null}`}
          onClick={() => {
            setIndex(0);
          }}
        >
          Capital social
        </div>
        <div
          className={`tabHead ${index === 1 ? `active` : null}`}
          onClick={() => {
            setIndex(1);
          }}
        >
          Administration
        </div>
        <div
          className={`tabHead ${index === 2 ? `active` : null}`}
          onClick={() => {
            setIndex(2);
          }}
        >
          Comptes annuels
        </div>
        <div
          className={`tabHead ${index === 3 ? `active` : null}`}
          onClick={() => {
            setIndex(3);
          }}
        >
          Approbation des comptes annuels
        </div>
      </div>
      <div className="tabContant" hidden={index != 0}>
        <table>
          <thead>
            {listHeader.map((item, i) => (
              <th key={item.id}>{item.str}</th>
            ))}
          </thead>
          <tbody>
            {listCapitalSocial.map((item, i) => (
              <tr key={item.id}>
                <td>{item.caracteristiques}</td>
                <td>{item.SA}</td>
                <td>{item.SARL}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="tabContant" hidden={index != 1}>
        <table>
          <thead>
            {listHeader.map((item, i) => (
              <th key={item.id}>{item.str}</th>
            ))}
          </thead>
          <tbody>
            {listAdministration.map((item, i) => (
              <tr key={item.id}>
                <td>{item.caracteristiques}</td>
                <td>{item.SA}</td>
                <td>{item.SARL}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="tabContant" hidden={index != 2}>
        <table>
          <thead>
            {listHeader.map((item, i) => (
              <th key={item.id}>{item.str}</th>
            ))}
          </thead>
          <tbody>
            {listComptesAnnuels.map((item, i) => (
              <tr key={item.id}>
                <td>{item.caracteristiques}</td>
                <td>{item.SA}</td>
                <td>{item.SARL}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="tabContant" hidden={index != 3}>
        <table>
          <thead>
            {listHeader.map((item, i) => (
              <th key={item.id}>{item.str}</th>
            ))}
          </thead>
          <tbody>
            {listApprobationDesComptesAnnuels.map((item, i) => (
              <tr key={item.id}>
                <td>{item.caracteristiques}</td>
                <td>{item.SA}</td>
                <td>{item.SARL}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Tabs;
