import React from "react";
import CRUDialog from "../../../components/AdminPanel/CRUDialog/CRUDialog";
import Datatables from "../../../components/AdminPanel/Datatable/Datatables";
import Sidebar from "../../../components/AdminPanel/SideBar/Sidebar";
interface Props {
  label: string;
  rows: any;
  cols: any;
  children?: any;
  onClick?: () => void;
  refreshRows?: () => void;
}
const AdminPageContainer = ({
  label,
  rows,
  cols,
  children,
  onClick,
  refreshRows,
}: Props) => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <div className="dataTables">
          <h1 className="titre">Liste des {label}</h1>
          <div className="crud">
            <CRUDialog
              type={label}
              label="Ajouter"
              onClick={onClick}
              refreshRows={refreshRows}
            >
              {children}
            </CRUDialog>
          </div>

          <Datatables
            columns={cols}
            rows={rows}
            height={600}
            width={"100%"}
            label={label}
          />
        </div>
      </div>
    </div>
  );
};
export default AdminPageContainer;
