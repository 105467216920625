import AutorenewIcon from "@mui/icons-material/Autorenew";
import Button from "@mui/material/Button";
import * as React from "react";
import MUIDialog from "../Dialog/MUIDialog";

interface Props {
  label: string;
  type: string;
  children?: any;
  onClick?: () => void;
  refreshRows?: () => void;
}
export default function CRUDialog({
  refreshRows,
  onClick,
  label,
  type,
  children,
}: Props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        style={{
          float: "right",
          color: "green",
          border: "1px solid green",
        }}
      >
        {label}
      </Button>
      <Button
        style={{
          marginRight: "5px",
          float: "right",
          color: "grey",
          border: "none",
        }}
        variant="outlined"
        onClick={refreshRows}
      >
        <AutorenewIcon />
      </Button>
      <MUIDialog
        open={open}
        handleClose={handleClose}
        onClick={onClick}
        buttonContent={label}
        titleContent={label + " des " + type}
      >
        {children}
      </MUIDialog>
    </div>
  );
}
