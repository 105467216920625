
function changeBg(){
    var header=document.getElementById('header');
    var scrollValue:number=window.scrollY;
    const scrollConst:number=150;
    if(scrollValue < scrollConst){
        header?.classList.remove('bgColor')
    }else{
        header?.classList.add('bgColor')
    }
}

window.addEventListener('scroll',changeBg);