import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { IItem } from "../../interfaces/IItem";
interface ICombo {
  placeholder: string;
  data?: Array<IItem>;
  value?: string;
  onChangeValue?: any;
  fullW?: boolean;
  className?: string;
  defaultValue?: number;
}
const ComboBox = ({
  placeholder,
  data,
  value,
  onChangeValue,
  fullW,
  className,
  defaultValue,
}: ICombo) => {
  return (
    <div>
      <FormControl
        className={className}
        fullWidth={fullW}
        sx={{ m: 1, minWidth: 130 }}
      >
        <InputLabel id="demo-simple-select-autowidth-label">
          {placeholder}
        </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={value}
          onChange={onChangeValue}
          autoWidth
          label={placeholder}
        >
          {data ? (
            data.map(({ label, value }: IItem) => {
              return (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem value={0}>{""}</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default ComboBox;
