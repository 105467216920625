import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header/Header";
import ProtectedRoute from "./components/Protection/ProtectedRoute";
import Clients from "./routes/AdminPanel/Clients/Clients";
import Login from "./routes/AdminPanel/Login/Login";
import Observe from "./routes/AdminPanel/Observe/Observe";
import Tasks from "./routes/AdminPanel/Tasks/Tasks";
import Timesheet from "./routes/AdminPanel/Timesheet/Timesheet";
import Users from "./routes/AdminPanel/Users/Users";
import Competitivite from "./routes/Competitivite/Competivite";
import Constitution from "./routes/Constitution/Constitution";
import Contact from "./routes/contact/Contact";
import Domicialisation from "./routes/Domiciliation/Domicialisation";
import Page401 from "./routes/ErrorsRedirection/401/Page401";
import Page404 from "./routes/ErrorsRedirection/404/Page404";
import FAQ from "./routes/FAQ/FAQ";
import Hero from "./routes/Hero/Hero";
import Simulateur from "./routes/Simulateur_De_Salaire/PageSimulateur";
const ROLES = {
  ADMIN: 1,
  USER: 0,
};
function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/">
          {/*Error pages*/}
          <Route path="error404" element={<Page404 />}></Route>
          <Route path="unauthorized" element={<Page401 />}></Route>
          {/*public pages*/}
          <Route index element={<Hero />}></Route>
          <Route path="contact" element={<Contact />}></Route>
          <Route path="faq" element={<FAQ />}></Route>
          <Route path="simulateur" element={<Simulateur />}></Route>
          <Route
            path="domiciliation_de_societe"
            element={<Domicialisation />}
          ></Route>
          <Route
            path="constitution_de_societe"
            element={<Constitution />}
          ></Route>
          <Route
            path="competitivite_salariale"
            element={<Competitivite />}
          ></Route>
          <Route path="login" element={<Login />} />
          {/*protected pages*/}
          <Route path="admin">
            <Route index element={<Page404 />}></Route>
            <Route element={<ProtectedRoute allowedRoles={ROLES.USER} />}>
              <Route path="timesheet" element={<Timesheet />} />
              <Route path="observe" element={<Observe />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoles={ROLES.ADMIN} />}>
              <Route path="observe" element={<Observe />} />
              <Route path="users" element={<Users />} />
              <Route path="tasks" element={<Tasks />} />
              <Route path="clients" element={<Clients />} />
            </Route>
          </Route>
        </Route>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
/**
 *           <ProtectedRoute
            isAuth={useAuth()}
            path="/timesheet"
            element={<Home />}
          />
          
 *           <Route path="timesheet">
            <Route index element={<Home />}></Route>
            <Route path=":taskID" element={<Users />}></Route>
            <Route path="new" element={<Users />}></Route>
          </Route>
          <Route path="users">
            <Route index element={<Users />}></Route>
            <Route path=":userID" element={<Users />}></Route>
            <Route path="new" element={<Users />}></Route>
          </Route>
 */
