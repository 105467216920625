import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface Props {
  allowedRoles: number;
}
const ProtectedRoute = ({ allowedRoles }: Props) => {
  const [userRole, seUserRole] = useState(0);
  useEffect(() => {
    Axios.get("http://localhost:3001/authenticate", {
      headers: {
        authorization: String("Bearer " + localStorage.getItem("token")),
      },
    }).then((response: any) => {
      let clientAccess = response.data.user.accessAdmin;
      if (clientAccess >= allowedRoles) {
        seUserRole(clientAccess);
      } else {
        seUserRole(-1);
      }
    });
  });
  return userRole >= allowedRoles ? <Outlet /> : <Navigate to="/login" />;
};
export default ProtectedRoute;
/*
auth ? <Outlet /> : <Navigate to="/login" />*/
