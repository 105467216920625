import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";
import styles from "../../../styles/variables.module.scss";
import "./Datatables.scss";

const datagridSx = {
  borderRadius: 2,
  "& .MuiDataGrid-main": { borderRadius: 2 },

  "& .MuiDataGrid-virtualScrollerRenderZone": {
    "& .MuiDataGrid-row": {
      "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" },
    },
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: styles.primary,
    color: styles.dark,
    fontSize: 16,
  },
};
interface Props {
  rows: Array<any>;
  columns: GridColDef[];
  height?: number;
  width?: string;
  label: string;
}
const Datatables = ({ rows, columns, height, width, label }: Props) => {
  return (
    <div
      className="datatables"
      style={{ height: height || 500, width: width || "100%" }}
    >
      <DataGrid
        sx={datagridSx}
        rows={rows}
        columns={columns}
        pageSize={9}
        rowsPerPageOptions={[5]}
      />
    </div>
  );
};

export default Datatables;
