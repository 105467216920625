import React from "react";
import { Col, Container, Row } from "react-bootstrap";

interface Props {
  children: React.ReactNode;
}

const FormContainer = ({ children }: Props) => {
  return (
    <Container className="py-3" style={{ marginBottom: "10%" }}>
      <Row className="justify-content-md-center">
        <Col>{children}</Col>
      </Row>
    </Container>
  );
};

export default FormContainer;
