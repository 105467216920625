import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import pagenotfoundImage from "../../../assets/images/illustrations/ill_error401.png";
import "./Page401.scss";
const Page401 = () => {
  return (
    <div className="pageNotFound">
      <h1>Oops..! 401 Page inaccessible </h1>
      <p>La page que vous recherchez est inaccessible.</p>
      <Link to="/">
        <span className="btnHome">Accueil</span>
      </Link>
      <img
        src={pagenotfoundImage}
        height="500"
        width="500"
        alt="not accessible illustration"
      />
    </div>
  );
};

export default Page401;
