import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { ICrudProps } from "../../../interfaces/ICrud";
import "./CrudClients.scss";

const CrudClients = ({ label, value }: ICrudProps) => {
  const [nameClient, setNameClient] = useState("");
  const [errName, setErrName] = useState(false);
  const [helperName, setHelperName] = useState("");
  const toAdd: boolean = label === "Ajouter";
  const [resultSuccess, setResultSuccess] = useState(false);
  const [resultMsg, setResultMsg] = useState("");
  const displayAlert = resultMsg === "" ? "none" : "";
  const severityAlert = resultSuccess ? "success" : "error";
  const [firstRender, setFirstRender] = useState(false);

  function findUser(id: any) {
    Axios.get(`http://localhost:3001/client/${id}`).then((response: any) => {
      if (response.data.found) {
        const client = response.data.client;
        setNameClient(client.nameClient);
      } else {
        alert(response.data.message);
      }
    });
  }
  const verifyData = () => {
    const regexAlphabet = new RegExp("^[A-Za-z\\s-]+$");

    const verifyName = regexAlphabet.test(nameClient);

    if (!verifyName) {
      setErrName(true);
      setHelperName("Le nom doit être alphabétique.");
    } else {
      setErrName(false);
      setHelperName("");
    }
    const allTrue = verifyName;
    return allTrue;
  };
  const addClient = () => {
    let allTrue = verifyData();
    if (allTrue) {
      const data = {
        name: nameClient.trim(),
      };
      Axios.post("http://localhost:3001/client/add", data).then(
        (response: any) => {
          setResultMsg(response.data.message);
          setResultSuccess(response.data.added);
        }
      );
      clearCRUD();
    }
  };
  const updateClient = () => {
    let allTrue = verifyData();
    if (allTrue) {
      const data = {
        id: value,
        name: nameClient.trim(),
      };
      Axios.post("http://localhost:3001/client/update", data).then(
        (response: any) => {
          setResultMsg(response.data.message);
          setResultSuccess(response.data.updated);
        }
      );
      clearCRUD();
    }
  };
  const clearCRUD = () => {
    setNameClient("");
  };
  useEffect(() => {
    if (!firstRender && !toAdd) {
      findUser(value);
      setFirstRender(true);
    }
  }, [firstRender]);
  return (
    <div className="crudContainer">
      <Stack sx={{ width: "100%", display: displayAlert }} spacing={2}>
        <Alert severity={severityAlert}>{resultMsg}</Alert>
      </Stack>
      <FormControl sx={{ m: 1, width: "35ch" }} variant="outlined">
        <TextField
          className="item"
          id="tf outlined-error-helper-text"
          label="Nom"
          onChange={(e: any) => {
            setNameClient(e.target.value);
          }}
          value={nameClient}
          error={errName}
          helperText={helperName}
        />
        <Button onClick={toAdd ? addClient : updateClient}>{label}</Button>
      </FormControl>
    </div>
  );
};

export default CrudClients;
/**
 * error
 *         helperText="Incorrect entry."

 */
