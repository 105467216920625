import AutorenewIcon from "@mui/icons-material/Autorenew";
import { FormLabel } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextField from "@mui/material/TextField";
import { GridColDef } from "@mui/x-data-grid";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Datatables from "../../../components/AdminPanel/Datatable/Datatables";
import Navbar from "../../../components/AdminPanel/NavBar/Navbar";
import Sidebar from "../../../components/AdminPanel/SideBar/Sidebar";
import ComboBox from "../../../components/MU_Select/MU_Select";
import { VerifyRole } from "../../../components/Protection/verifyRole";
import { IItem } from "../../../interfaces/IItem";
import "./Timesheet.scss";

const columns: GridColDef[] = [
  { field: "contentTask", headerName: "Tâche", flex: 2 },
  { field: "nameClient", headerName: "Client", flex: 2 },
  { field: "year", headerName: "Exercice", flex: 1 },
  { field: "duration", headerName: "Qtes", flex: 1 },
  { field: "comment", headerName: "Commentaire", flex: 3 },
];
function checkToVerify(e: any) {
  const timesheet = JSON.parse(e.target.value);
  Axios.post(`http://localhost:3001/timesheet/toVerify`, timesheet).then(
    (response: any) => {}
  );
}

const Home = () => {
  const [selectedClient, setSelectedClient] = useState();
  const [selectedTask, setSelectedTask] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [durationValue, setDuration] = useState<number>();
  const [commentValue, setComment] = useState("");
  //
  const [clientDataRows, setClientRows] = useState<IItem[]>([]);
  const [taskDataRows, setTaskRows] = useState<IItem[]>([]);
  const [yearDataRows, setYearRows] = useState<IItem[]>([]);
  const [timesheetRows, setTimesheetRows] = useState<any[]>([]);
  const [firstRender, setFirstRender] = useState(false);
  const now = new Date();
  const [date, changeDate] = useState(now.toISOString().slice(0, 10));
  const [idUser, setIdUser] = useState(0);
  const [sumDurations, setSumDurations] = useState(0);
  const [commentModifyValue, setCommentModifyValue] = useState("");
  const [selectedTimesheetClient, setSelectedTimesheetClient] = useState(0);
  const [selectedTimesheetTask, setSelectedTimesheetTask] = useState(0);

  function verifyAll() {
    return (
      selectedClient != undefined &&
      selectedTask != undefined &&
      selectedYear != undefined &&
      durationValue != undefined
    );
  }
  const submitReview = () => {
    const verifAll = verifyAll();
    const data = {
      idClient: selectedClient,
      idUser: idUser,
      idTask: selectedTask,
      date: date,
      year: selectedYear,
      duration: durationValue,
      toVerify: 0,
      comment: commentValue,
    };
    if (verifAll) {
      Axios.post("http://localhost:3001/timesheet/add", data).then(
        (response: any) => {}
      );
      alert("Ajout avec succès");
    } else {
      alert("Vérifiez vos champs");
    }
  };
  const checkBoxColumn = [
    {
      field: "toVerify",
      headerName: "À vérifier",
      flex: 1.5,
      renderCell: (params: any) => {
        const value = JSON.stringify({
          user: idUser,
          client: params.row.idClient,
          task: params.row.idTask,
          date: date,
        });
        return (
          <Checkbox
            onChange={checkToVerify}
            value={value}
            defaultChecked={params.row.toVerify == 1}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 3,
      renderCell: (params: any) => {
        const value = JSON.stringify({
          user: idUser,
          client: params.row.idClient,
          task: params.row.idTask,
          date: date,
          comment: params.row.comment,
        });
        return (
          <div className="cellAction">
            <button
              value={value}
              className="modifyButton"
              onClick={handleClickOpen}
            >
              Modifier
            </button>
            <div className={`deleteButton`}>
              <button value={value} onClick={clickToDelete}>
                Supprimer
              </button>
            </div>
          </div>
        );
      },
    },
  ];
  function handleOpenModify(timesheet: any) {
    setCommentModifyValue(timesheet.comment);
  }

  const loadDataTimesheet = () => {
    Axios.get(`http://localhost:3001/authenticate`, {
      headers: {
        authorization: String("Bearer " + localStorage.getItem("token")),
      },
    }).then((response: any) => {
      var rows: Array<any> = [];
      setIdUser(response.data.user.idUser);
      Axios.get(`http://localhost:3001/timesheet/${idUser}&${date}`).then(
        (response: any) => {
          var i = 1;
          var sum = 0;
          response.data.forEach(function (timesheet: any) {
            timesheet["id"] = i;
            Axios.get(
              `http://localhost:3001/client/${timesheet["idClient"]}`
            ).then((response: any) => {
              timesheet["nameClient"] = response.data.client.nameClient;
            });
            Axios.get(`http://localhost:3001/task/${timesheet["idTask"]}`).then(
              (response: any) => {
                timesheet["contentTask"] = response.data.task.contentTask;
              }
            );
            sum += Number(timesheet.duration);
            rows.push(timesheet);
            i++;
          });
          setSumDurations(sum);
          setTimesheetRows(rows);
        }
      );
    });
  };
  const loadDataCombo = () => {
    Axios.get("http://localhost:3001/client/list").then((response: any) => {
      var rows: Array<IItem> = [];
      response.data.forEach(function (client: any) {
        rows.push({
          label: client.nameClient,
          value: client.id,
        });
      });
      setClientRows(rows);
    });
    Axios.get("http://localhost:3001/task/list").then((response: any) => {
      var rows: Array<IItem> = [];
      response.data.forEach(function (task: any) {
        rows.push({
          label: task.contentTask,
          value: task.id,
        });
      });
      setTaskRows(rows);
    });
    var rows: Array<IItem> = [];
    for (let i = now.getFullYear(); i > 2000; i--) {
      rows.push({
        label: String(i),
        value: i,
      });
    }
    setYearRows(rows);
  };
  useEffect(() => {
    if (!firstRender) {
      loadDataTimesheet();
      loadDataCombo();
      setFirstRender(true);
    }
  });
  function clickToDelete(e: any) {
    const timesheet = JSON.parse(e.target.value);
    Axios.post(`http://localhost:3001/timesheet/delete`, timesheet).then(
      (response: any) => {}
    );
    alert("supprimé avec succès");
  }
  const role: number = VerifyRole();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e: any) => {
    const timesheet = JSON.parse(e.target.value);
    setSelectedTimesheetClient(timesheet.client);
    setSelectedTimesheetTask(timesheet.task);
    setOpen(true);
    handleOpenModify(timesheet);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function handleClickModify() {
    const data = {
      user: idUser,
      client: selectedTimesheetClient,
      task: selectedTimesheetTask,
      date: date,
      comment: commentModifyValue,
    };
    Axios.post(`http://localhost:3001/timesheet/modify`, data).then(
      (response: any) => {}
    );
    alert("modifié avec succès");
    handleClose();
  }
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="listContainer">
          <h4>Saisie d'une tâche: </h4>
          <div className="items">
            <div className="item">
              <ComboBox
                placeholder="Client"
                onChangeValue={(e: any) => {
                  setSelectedClient(e.target.value);
                }}
                data={clientDataRows}
                value={selectedClient}
              />
            </div>
            <div className="item">
              <ComboBox
                placeholder="Tâche"
                onChangeValue={(e: any) => {
                  setSelectedTask(e.target.value);
                }}
                data={taskDataRows}
                value={selectedTask}
              />
            </div>
            <div className="item">
              <ComboBox
                placeholder="Exercice"
                onChangeValue={(e: any) => {
                  setSelectedYear(e.target.value);
                }}
                value={selectedYear}
                data={yearDataRows}
              />
            </div>
            <div className="item">
              <TextField
                id="outlined-number"
                label="Quantités"
                type="number"
                placeholder="0"
                inputProps={{ step: 0.25 }}
                value={durationValue}
                onChange={(e: any) => {
                  const value = e.target.value;
                  if (value < 0) setDuration(0);
                  else setDuration(value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="item">
              <TextareaAutosize
                className="TextareaAutosize"
                aria-label="Commentaire"
                placeholder="Commentaire"
                value={commentValue}
                onChange={(e: any) => {
                  setComment(e.target.value);
                }}
                style={{
                  width: 200,
                  height: 55,
                }}
              />
            </div>
            <div className="item">
              <Button onClick={submitReview} variant="contained">
                Ajouter
              </Button>
            </div>
          </div>
          <hr />
          <div>
            <div className="row mb-3">
              <div className="col-md-4 text-justify">
                <Form.Label>
                  {" "}
                  <h4>Sélectionner une date</h4>{" "}
                </Form.Label>
                <Form.Group style={{ display: "flex" }} controlId="dob">
                  <Form.Control
                    type="date"
                    name="dob"
                    placeholder="Date of Birth"
                    style={{ backgroundColor: "transparent" }}
                    value={String(date)}
                    defaultValue={String(date)}
                    onChange={(e: any) => {
                      changeDate(e.target.value);
                    }}
                  />
                  <Button
                    style={{
                      marginRight: "5px",
                      float: "right",
                      color: "grey",
                      border: "none",
                    }}
                    variant="outlined"
                    onClick={loadDataTimesheet}
                  >
                    <AutorenewIcon />
                  </Button>
                </Form.Group>
                <FormLabel>Total quantités: {sumDurations} heure(s)</FormLabel>
              </div>
            </div>
            <Datatables
              rows={timesheetRows}
              columns={columns.concat(checkBoxColumn)}
              label={"tâches"}
            />
          </div>
        </div>
      </div>
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Modification</DialogTitle>
          <DialogContent>
            <TextareaAutosize
              className="TextareaAutosize"
              aria-label="Commentairee"
              placeholder="Commentaire"
              value={commentModifyValue}
              onChange={(e: any) => {
                setCommentModifyValue(e.target.value);
              }}
              style={{
                width: 200,
                height: 55,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
            <Button onClick={handleClickModify}>Modifier</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default Home;
