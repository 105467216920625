export const questions =[
    {
    summary: "Ai-je besoin d'un capital important ?",
    details: "Si la réponse est non, choisissez une S.A. car vous avez la possibilité de ne libérer que le quart du capital social (soit 7 750 EUR)"
},
{
    summary: "Ai-je besoin de discrétion ?",
    details: "Si tel est le cas, seule une S.A. pourra répondre à votre besoin car l'identité des actionnaires n'est pas publique. Attention : les identités des associés fondateurs et des administrateurs sont publiées au Mémorial (équivalent du Journal Officiel) et au Registre du Commerce luxembourgeois",
},
{
    summary:"Ai-je besoin d'une structure légère (associé unique) ou non (pluralité d'associés) ?",
    details:"Si vous êtes seul, alors optez pour la SARL unipersonnelle. Les contraintes administratives sont réduites au minimum"
}    

]