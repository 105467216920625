export enum Bareme{
    UN,
    UN_A,
    DEUX,
    VIDE
}
export enum Status{
    Celibataire,
    Marie,
    Divorce_M3,
    Divorce_P3,
}
export enum Situation{
    Sans_enfants,
    Avec_enfants
}