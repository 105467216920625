import LockIcon from "@mui/icons-material/Lock";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import classnames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BiMenuAltRight } from "react-icons/bi";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "react-whatsapp-widget/dist/index.css";
import logo from "../../assets/images/logo/logo_cogito_bf.png";
import pdfRGPD from "../../assets/pdf/rgpd.pdf";
import classes from "./Header.module.scss";
import MessengerCustomerChat from "react-messenger-customer-chat";
import vars from "../../styles/variables.module.scss";
import "./header.ts";
import "./Scroll.scss";

interface HeaderProps {
  fixed?: boolean;
  transparent?: boolean;
}
const Header: FC<HeaderProps> = ({ fixed, transparent }) => {
  const PAGE_ID = "107933328576464";
  const APP_ID = "699839797979298";
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  const ctaClickHandler = () => {
    menuToggleHandler();
    navigate("/contact");
  };

  const [show, setShow] = useState(true);
  const controlNavbar = () => {
    if (window.scrollY > 100) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);
  console.log(process.env);
  return (
    <>
      <header id="header" className={classnames(classes.header, `${show}`)}>
        <div className={classes.header__content}>
          <Link to="/" className={classes.header__content__logo}>
            <img src={logo} alt="" />
          </Link>
          <nav
            className={`${classes.header__content__nav} ${
              menuOpen && size.width < 768 ? classes.isMenu : ""
            }`}
          >
            <ul>
              <li>
                <NavLink
                  style={(navData) =>
                    navData.isActive
                      ? {
                          background: "transparent",
                          borderBottom: "1px solid black",
                        }
                      : {}
                  }
                  to="/"
                  onClick={menuToggleHandler}
                >
                  <span>Accueil</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={(navData) =>
                    navData.isActive
                      ? {
                          background: "transparent",
                          borderBottom: "1px solid black",
                        }
                      : {}
                  }
                  to="/constitution_de_societe"
                  onClick={menuToggleHandler}
                >
                  Constitution de société
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={(navData) =>
                    navData.isActive
                      ? {
                          background: "transparent",
                          borderBottom: "1px solid black",
                        }
                      : {}
                  }
                  to="/domiciliation_de_societe"
                  onClick={menuToggleHandler}
                >
                  Domiciliation de société
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={(navData) =>
                    navData.isActive
                      ? {
                          background: "transparent",
                          borderBottom: "1px solid black",
                        }
                      : {}
                  }
                  to="/competitivite_salariale"
                  onClick={menuToggleHandler}
                >
                  Compétitivité salariale
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={(navData) =>
                    navData.isActive
                      ? {
                          background: "transparent",
                          borderBottom: "1px solid black",
                        }
                      : {}
                  }
                  to="/simulateur"
                  onClick={menuToggleHandler}
                >
                  Simulateur
                </NavLink>
              </li>
            </ul>
            <button onClick={ctaClickHandler}>Contact</button>
          </nav>
          <div className={classes.header__content__toggle}>
            {!menuOpen ? (
              <BiMenuAltRight onClick={menuToggleHandler} />
            ) : (
              <AiOutlineClose onClick={menuToggleHandler} />
            )}
          </div>
        </div>
      </header>

      <Link to="/faq" className="FAQ">
        <QuestionMarkIcon className="hideIcon" />
        <p className="hideText">F.A.Q</p>
      </Link>
      <a target="_blank" href={pdfRGPD} className="RGPD" rel="noreferrer">
        <LockIcon className="hideIcon" />
        <p className="hideText">R.G.P.D</p>
      </a>
      <MessengerCustomerChat
        pageId={PAGE_ID}
        appId={APP_ID}
        themeColor={vars.dark}
        language="fr_FR"
      />
    </>
  );
};

export default Header;
