import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { BiMinusCircle, BiPlusCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import ill_hero from "../../assets/images/illustrations/ill_hero.png";
import ill_info from "../../assets/images/illustrations/ill_info.png";
import logo from "../../assets/images/logo/logo_sarl_bm.png";
import svgIcon from "../../assets/images/Services.svg";
import "./Hero.scss";

const Hero = () => {
  return (
    <>
      <div className="container container_hero">
        <div className="definition">
          <div className="logo">
            <img className="img-fluid" src={logo} alt="cogito_sarl" />
          </div>
          <h5 className="grey">
            COGITO SERVICES SARL est un cabinet d'Expertise comptable né fin
            2008 du spin-off de la société luxembourgeoise{" "}
            <span>
              {" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.coprocess.lu/en"
                className="text_orange"
              >
                {" "}
                COPROCESS S.A
              </a>{" "}
            </span>
            .
          </h5>
          <Link to="simulateur">
            <button className="btn_simulateur">
              <span className="gear_icon">
                <img src={svgIcon} alt="" />
              </span>
              Simulateur de salaire
            </button>
          </Link>
        </div>
        <div className="illus">
          <img className="img-fluid" src={ill_hero} alt="illustration" />
        </div>
      </div>
      <div className="container container_hero">
        <div className="illus disnone">
          <img className="img-fluid" src={ill_info} alt="illustration" />
        </div>
        <div className="definition">
          <h1>Qui sommes-nous?</h1>
          <p className="grey">
            COGITO SERVICES SARL est un cabinet d'Expertise comptable né fin
            2008 du spin-off de la société luxembourgeoise COPROCESS S.A.
            (Conseil économique) pour développer des services d'ingénierie
            financière à destination des entrepreneurs ou des entreprises
            étrangères souhaitant disposer au Grand-Duché d'une structure
            juridique optimisée pour des opérations au Grand-Duché de Luxembourg
            ou à l'international.
          </p>
        </div>
      </div>
      <div className="container">
        <p className="fw-bold">
          Savez-vous que le Grand-Duché de Luxembourg est socialement nettement
          plus compétitif que ses voisins ? Pour en savoir plus, n'hésitez pas à
          consulter notre page{" "}
          <Link to="competitivite_salariale" className="text_orange">
            Compétitivité salariale
          </Link>
          .
        </p>
        <p className="fw-bold">Sont incluses du champ de nos activités :</p>
        <div className="container">
          <div className="row">
            <div className="col m-2">
              <div className="plusMinus">
                {" "}
                <BiPlusCircle />{" "}
              </div>
              <p>
                La création de structures établies spécifiquement au Grand-Duché
                de Luxembourg en vue d'éluder tout ou partie de l'impôt dans le
                pays d'origine de l'entrepreneur
              </p>
            </div>
            <div className="col m-2">
              <div className="plusMinus">
                <BiPlusCircle />
              </div>
              <p>
                {" "}
                La création ou l'administration de structures destinées au
                blanchiment de capitaux ou au financement du terrorisme
              </p>
            </div>
            <div className="w-100"></div>
            <div className="col m-2">
              <div className="plusMinus">
                <BiPlusCircle />
              </div>
              <p>
                {" "}
                L'administration de structures opaques où le ou les
                bénéficiaires économiques réels n'apparaissent ni en qualité
                d'actionnaire fondateur, ni en qualité d'administrateur
              </p>
            </div>
            <div className="col m-2">
              <div className="plusMinus">
                <BiPlusCircle />
              </div>
              <p>
                {" "}
                De manière générale, toute activité en infraction avec la
                législation luxembourgeoise et/ou européenne
              </p>
            </div>
          </div>
        </div>
        <p className="fw-bold">Sont exclues dans le champ de nos activités :</p>
        <div className="container">
          <div className="row">
            <div className="col m-2">
              {" "}
              <div className="plusMinus">
                <BiMinusCircle />
              </div>
              <p>
                {" "}
                La création de structures commerciales impliquant au moins deux
                pays (par exemple achat de matériels en Belgique pour les
                revendre hors de Belgique)
              </p>
            </div>
            <div className="col m-2">
              {" "}
              <div className="plusMinus">
                <BiMinusCircle />
              </div>
              <p>
                {" "}
                La domiciliation de sociétés au sens de la loi du 31 mai 1999
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
