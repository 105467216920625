import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GroupIcon from "@mui/icons-material/Group";
import PreviewIcon from "@mui/icons-material/Preview";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./SideBar.scss";
interface Props {
  role?: number;
}
const Sidebar = ({ role }: Props) => {
  const [admin, setAdmin] = useState(false);
  const navigate = useNavigate();
  function test() {}
  useEffect(() => {
    Axios.get("http://localhost:3001/authenticate", {
      headers: {
        authorization: String("Bearer " + localStorage.getItem("token")),
      },
    }).then((response: any) => {
      if (response.data.auth == false) {
        navigate("/login");
      } else {
        let clientAccess = response.data.user.accessAdmin;
        if (clientAccess == 1) setAdmin(true);
      }
    });
  }, []);
  const className = admin == false ? "dn" : "";
  return (
    <>
      <div className="sidebar">
        <div className="center">
          <ul>
            <Link to="/admin/timesheet">
              <li>
                <CalendarMonthIcon className="icon" />
                <span>Timesheet</span>
              </li>
            </Link>
            <Link to="/admin/observe">
              <li>
                <PreviewIcon className="icon" />
                <span>Observer</span>
              </li>
            </Link>
            <Link className={className} to="/admin/users">
              <li>
                <AdminPanelSettingsIcon className="icon" />
                <span>Utilisateurs</span>
              </li>
            </Link>
            <Link className={className} to="/admin/clients">
              <li>
                <GroupIcon className="icon" />
                <span>Clients</span>
              </li>
            </Link>
            <Link className={className} to="/admin/tasks">
              <li>
                <AssignmentIcon className="icon" />
                <span>Tâches</span>
              </li>
            </Link>
          </ul>
        </div>
        <div className="bottom"></div>
      </div>
    </>
  );
};

export default Sidebar;
