import LoginIcon from "@mui/icons-material/Login";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import FormContainer from "../../../components/AdminPanel/FormContainer/FormContainer";
import "./Login.scss";

const Login = () => {
  const [emailLog, setEmailLog] = useState("");
  const [passwordLog, setPasswordLog] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  Axios.defaults.withCredentials = true;
  //
  const login = () => {
    const data = {
      email: emailLog,
      password: passwordLog,
    };
    Axios.post("http://localhost:3001/login", data).then((response: any) => {
      if (response.data.auth == false) {
        setErrMsg(response.data.message);
      } else {
        localStorage.setItem("token", response.data.accessToken);
        navigate("/admin/timesheet");
      }
    });
  };
  //
  useEffect(() => {
    Axios.get("http://localhost:3001/authenticate", {
      headers: {
        authorization: String("Bearer " + localStorage.getItem("token")),
      },
    }).then((response: any) => {
      if (response.data.user) {
        navigate("/admin/timesheet");
      }
    });
  });
  return (
    <FormContainer>
      <p className={errMsg ? "errmsg" : "offscreen"}>{errMsg}</p>
      <Form id="formContainer">
        <span className="loginIcon">
          <LoginIcon style={{ fontSize: "40px" }} />
        </span>
        <h1>Se connceter</h1>
        <Form.Group controlId="email" className="my-3">
          <Form.Label className="left">Adresse Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="E-Mail"
            value={emailLog}
            onChange={(e) => {
              setEmailLog(e.target.value);
            }}
            required
            style={{ width: "70%", margin: "auto" }}
          />
        </Form.Group>
        <Form.Group controlId="password" className="my-3">
          <Form.Label className="left">Mot de passe</Form.Label>
          <Form.Control
            type="password"
            placeholder="Mot de passe"
            value={passwordLog}
            onChange={(e) => {
              setPasswordLog(e.target.value);
            }}
            required
            style={{ width: "70%", margin: "auto" }}
          />
        </Form.Group>
        <Row className="justify-content-center">
          <Button id="btnSubmit" className="my-3" onClick={login}>
            Login
          </Button>
        </Row>
      </Form>
    </FormContainer>
  );
};

export default Login;
