import { GridColDef } from "@mui/x-data-grid";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import AdminPageContainer from "../../../components/AdminPanel/AdminPageContainer/AdminPageContainer";
import CrudClients from "../../../components/AdminPanel/Crud_clients/CrudClients";
import MUIDialog from "../../../components/AdminPanel/Dialog/MUIDialog";

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "nameClient", headerName: "Nom", flex: 3 },
];

const Clients = () => {
  const [openModify, setOpenModify] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [firstRender, setFirstRender] = useState(false);
  const [chosenModifyClient, setChosenModifyClient] = useState(-1);
  const [chosenDeleteClient, setChosenDeleteClient] = useState(-1);
  const [rows, setRows] = useState([]);
  const refreshData = () => {
    Axios.get("http://localhost:3001/client/list").then((response: any) => {
      setRows(response.data);
    });
  };
  useEffect(() => {
    if (!firstRender) {
      refreshData();
      setFirstRender(true);
    }
  }, [firstRender]);
  const handleOpenModify = (e: any) => {
    setChosenModifyClient(e.target.value);
    setOpenModify(true);
  };

  const handleCloseModify = () => {
    setOpenModify(false);
  };
  const handleOpenDelete = (e: any) => {
    setChosenDeleteClient(e.target.value);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const actionColumn = [
    {
      field: "actions",
      headerName: "Actions",
      flex: 2,
      renderCell: (params: any) => {
        const id = params.row.id;
        return (
          <div className="cellAction">
            <button
              value={id}
              onClick={handleOpenModify}
              className="modifyButton"
            >
              Modifier
            </button>
            <button
              value={id}
              onClick={handleOpenDelete}
              className="deleteButton"
            >
              Supprimer
            </button>
          </div>
        );
      },
    },
  ];
  function handleDelete() {
    const data = {
      idClient: chosenDeleteClient,
    };
    Axios.post("http://localhost:3001/client/delete", data).then(
      (response: any) => {
        if (response.data.deleted == false) {
          console.log(response.data.message);
        } else {
          handleCloseDelete();
        }
      }
    );
  }
  return (
    <>
      <AdminPageContainer
        label="clients"
        rows={rows}
        cols={columns.concat(actionColumn)}
        refreshRows={refreshData}
      >
        <CrudClients label="Ajouter" />
      </AdminPageContainer>
      {/** Click Modifier */}
      <MUIDialog
        open={openModify}
        handleClose={handleCloseModify}
        buttonContent={"Modifier"}
        titleContent={"Modifier des clients"}
      >
        <CrudClients label="Modifier" value={chosenModifyClient} />
      </MUIDialog>
      {/** Click supprimer */}
      <MUIDialog
        open={openDelete}
        handleClose={handleCloseDelete}
        buttonContent={"Supprimer"}
        titleContent={"Supprimer des clients"}
        onClick={handleDelete}
        value={chosenDeleteClient}
        content={"Est-ce que vous êtes sûr de supprmier ce client?"}
      ></MUIDialog>
    </>
  );
};

export default Clients;
