import React from "react";
import "./dom.scss";
import illustration from "../../assets/images/illustrations/ill_dom.png";
import SimpleAccordion from "../../components/Accordion/SimpleAccordion";
import { questions } from "./questions";

const Domicialisation = () => {
  return (
    <>
      <div className="container container_hero">
        <div>
          <h1>Domiciliation de société</h1>
          <h2 className="orange">Qu'est-ce qu'une domiciliation?</h2>
          <div className="article">
            <div className="contenu container_hero">
              <p>
                « Lorsqu'une société établit auprès d'un tiers un siège pour y
                exercer une activité dans le cadre de son objet social et que ce
                tiers preste des services quelconques liés à cette activité, la
                société et ce tiers, appelé domiciliataire, sont tenus de
                conclure par écrit une convention dite de domiciliation. »
                <p className="source">Art. 1er. (1) de la Loi du 31/05/1999</p>
              </p>
            </div>
            <div className="illus">
              <img
                src={illustration}
                alt="illustration"
                className="img-fluid"
              />
            </div>
          </div>

          <p>
            Pratiquement, une domiciliation correspond au siège social de la
            société, c'est-à-dire au lieu où sont reçus tous les courriers de la
            société et où sont conservés tous les documents légaux (pièces
            comptables originales, comptabilité, registre des actionnaires,
            minutes des Conseils d'administration et des assemblées générales,
            etc.). C'est ce que l'on appelle l'administration centrale de la
            société.
          </p>
          <p>
            La domiciliation n'implique donc pas la mise à disposition de
            bureaux où recevoir la clientèle, ainsi que la mise à disposition
            d'un central téléphonique (nous ne fournissons pas de tels
            services).
          </p>
          <p>
            Dans certains cas, vous devrez donner de la substance à votre
            société.
          </p>
          {questions.map((item, i) => (
            <SimpleAccordion summary={item.summary} details={item.details} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Domicialisation;
