import React, { useState } from "react";
import ill_FAQ from "../../assets/images/illustrations/ill_FAQ.png";
import { list } from "../FAQ/data";
import "./FAQ.scss";

const FAQ = () => {
  const [selected, setSelected] = useState(null);
  const toggle = (i: any) => {
    if (selected === i) return setSelected(null);
    setSelected(i);
  };

  return (
    <div className="container container_hero">
      <div className="illus disnone">
        <img className="img-fluid" src={ill_FAQ} alt="illustration" />
      </div>
      <div className="wrapper">
        <div className="accordion">
          <h2>FOIRE AUX QUESTIONS</h2>
          {list.map((item, i) => (
            <div className="item">
              <div className="title" key={item.id} onClick={() => toggle(i)}>
                <p className="question">{item.question}</p>
                <span> {selected === i ? "-" : "+"} </span>
              </div>
              <div
                className={selected === i ? "contentShow" : "content"}
                key={item.id}
              >
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
